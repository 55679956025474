<template>
  <div class="home" :style="'background-image:url('+require('@/assets/bg.png')+')'">
    <img :src="require('@/assets/logo-vertical.png')" class="logo" />
    <speechItem :load="load" @changeMessage="(r) => userSendMessage(r)" />
    <textItem :text="speakText" ref="textItem" />
    <!-- <div>
      <p v-for="(item, index) in List" :key="index">
        {{
          Number(item.type) == 1 ? "KULLANICI : " + item.text : "QUANTUM : " + item.text
        }}
      </p>

      <div v-if="load"><i class="fas fa-spinner fa-spin"></i> Düşünüyor</div>
    </div> -->
  </div>
</template>

<script>
// @ is an alias to /src
import speechItem from "@/components/speechItem.vue";
import textItem from "@/components/textToItem";
import axios from "axios";
export default {
  name: "HomePage",
  components: {
    speechItem,
    textItem,
  },
  data() {
    return {
      List: [],
      speakText: "",
      load: false,
    };
  },
  methods: {
    userSendMessage(val) {
      this.List.push({ tpye: 1, text: val });
      this.getAnswer(val).then((res) => {
        this.List.push({ type: 2, text: res });
      });
    },
    async getAnswer() {
      this.load = true;
      const requestBody = {
        model: "gpt-3.5-turbo",
        temperature: 0.2,
        top_p: 0.1,
        frequency_penalty: 0,
        messages: [
          {
            role: "system",
            content:
              "SEN MSD İLAÇ HAYVAN SAĞLIĞI Firmasına ait dijital bir asistansın, yeni nesil bir yapay zeka modelidir. ve MSD İLAÇ HAYVAN SAĞLIĞI firması için ürettikleri ilaçlara gelen soruları cevaplarsın  işte ilaçlar :"+
              "Neo-Caf, sığırlarda ve koyunlarda deri ve ayak enfeksiyonlarının tedavisinde kullanılan bir topikal antibakteriyel spreydir. Bileşimi: 200 ml sprey, 5 gram oksitetrasiklin hidroklorür içerir. Oksitetrasiklin hidroklorür, geniş spektrumlu bir antibiyotik olup, birçok Gram negatif ve Gram pozitif bakteriye karşı etkilidir. Neo-Caf sadece topikal olarak kullanılır ve sistemik emilimi önemsizdir. Tedavi edilecek bölgeye 15-20 cm uzaklıktan sprey uygulanmalı ve uygulama 12 saatte bir tekrarlanmalıdır. Ayak enfeksiyonlarının tedavisinde, ayak temizlenmeli ve hayvan 24 saat kuru zeminde tutulmalıdır. Sprey, gebelik ve laktasyon dönemlerinde kullanılabilir, ancak göze ve göz çevresine temasından kaçınılmalıdır. Kutu basınçlıdır, ateşe yakın yerde kullanılmamalı ve içeriği solumamalısınız. Raf ömrü 2 yıldır, oda sıcaklığında saklanmalıdır. Kullanım sonrası kutu kırılmamalı veya ateşe atılmamalıdır. Neo-Caf 200 ml aerosol kutularda satılmaktadır. Ruhsat sahibi firma: Intervet Vet. İlaç Ltd. Şti., İstanbul. İmalatçı firma: Intervet Productions, İtalya. Lütfen unutmayın, kesinlikle veteriner hekiminizin talimatlarına uymanız önemlidir."+
              "Bovilis BVD, sığırların Bovine Viral Diarrhoea Virüsü (BVDV) ile fötal enfeksiyonuna karşı aktif bağışıklık sağlamak amacıyla kullanılan bir inaktif aşıdır. Aşı, her hayvana 2 ml intramusküler enjeksiyon şeklinde uygulanmalıdır. İlk aşılama, 8 aylıktan büyük sığırlara yapılmalıdır. İlk gebelikten yaklaşık 2 ay önce tek doz (2 ml) yapılır ve 4 hafta sonra ikinci doz uygulanır. Daha sonra her gebelikten yaklaşık 4 hafta önce tek doz aşı yapılır. Geniş çiftliklerde ise ilk aşılama sonrasında her 6 ayda bir tüm hayvanlar tekrar aşılanır. Aşı kullanılmadan önce çalkalanmalı ve steril enjeksiyon ekipmanı kullanılmalıdır. Aşının muhafaza edildiği sıcaklık aralığı 2-8°C arasında olmalıdır. İthalatçı firma: İntervet Türkiye, İstanbul. Üretici firma: Intervet International B.V., Hollanda. Aşıyla ilgili daha detaylı bilgi için veteriner hekiminize başvurmanız önerilir."
          },
          ...this.List.map((r) => {
            return {
              role: r.type == 1 ? "assistant" : "user",
              content: r.text,
            };
          }),
        ],
      };

      try {
        const response = await axios.post(
          "https://api.openai.com/v1/chat/completions",
          requestBody,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer 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`,
            },
          }
        );
        this.load = false;
        this.speakText = response.data.choices[0].message.content;
        setTimeout(() => {
          this.$refs.textItem.synthesizeSpeech();
        }, 100);

        return response.data.choices[0].message.content;
      } catch (error) {
        console.error("Error sending message to OpenAI:", error);
        this.load = false;
      }
    },
  },
};
</script>


<style lang="css">
  .logo{
    position: fixed;
    left:20px !important;
    top:20px !important;
  }
  .home{
    width: 100% !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 100vh !important;
  }
</style>
